import { lazy } from "react";

const routes = [
  {
    exact: true,
    path: "/money_movement",
    component: lazy(() => import("../pages/Finance/pendingPayments")),
  },
];

export default routes;
