import { lazy } from 'react';

const routes = [
  {
    exact: true,
    path: '/subcategory',
    component: lazy(() => import('../pages/SubCategory')),
  },
  {
    exact: true,
    path: '/subcategory/add',
    component: lazy(() => import('../pages/SubCategory/create')),
  },
  {
    exact: true,
    path: '/subcategory/edit/:id',
    component: lazy(() => import('../pages/SubCategory/edit')),
  },
];

export default routes;
