import { useCallback, useState } from "react";

// Production

export let URL = "https://masterservice.agrozone.in/";
export let BASE_URL = "https://masterservice.agrozone.in/master/";
export let AFFILIATE_URL = "https://userservice.agrozone.in/admin/";
export let PRODUCT_SERVICE_URL = "https://productservice.agrozone.in/";
export let PRODUCT_VARIANT_URL =
  "https://productservice.agrozone.in/product/variant/";
export let CUSTOMER_URL = "https://userservice.agrozone.in/";
export let ORDER_URL = "https://orderservice.agrozone.in/";
export let SHIPPER_URL = "https://shipperservice.agrozone.in/";
export let SELLER_URL = "https://sellerservice.agrozone.in/";

// UAT
// export let URL = "https://uat-masterservice.agrozone.in/";
// export let BASE_URL = "https://uat-masterservice.agrozone.in/master/";
// export let AFFILIATE_URL = "https://uat-userservice.agrozone.in/admin/";
// export let PRODUCT_SERVICE_URL = "https://uat-productservice.agrozone.in/";
// export let PRODUCT_VARIANT_URL =
//   "https://uat-productservice.agrozone.in/product/variant/";
// export let CUSTOMER_URL = "https://uat-userservice.agrozone.in/";
// export let ORDER_URL = "https://uat-orderservice.agrozone.in/";
// export let SHIPPER_URL = "https://uat-shipperservice.agrozone.in/";
// export let SELLER_URL = "https://uat-sellerservice.agrozone.in/";

// DEV
// export let URL = "https://dev-masterservice.agrozone.in/";
// export let BASE_URL = "https://dev-masterservice.agrozone.in/master/";
// export let AFFILIATE_URL = "https://dev-userservice.agrozone.in/admin/";
// export let PRODUCT_SERVICE_URL = "https://dev-productservice.agrozone.in/";
// export let PRODUCT_VARIANT_URL =
//   "https://dev-productservice.agrozone.in/product/variant/";
// export let CUSTOMER_URL = "https://dev-userservice.agrozone.in/";
// export let ORDER_URL = "https://dev-orderservice.agrozone.in/";
// export let SHIPPER_URL = "https://dev-shipperservice.agrozone.in/";
// export let SELLER_URL = "https://dev-sellerservice.agrozone.in/";

export const useCenteredTree = (defaultTranslate = { x: 0, y: 0 }) => {
  const [translate, setTranslate] = useState(defaultTranslate);
  const containerRef = useCallback((containerElem) => {
    if (containerElem !== null) {
      const { width, height } = containerElem.getBoundingClientRect();
      setTranslate({ x: width / 2, y: height / 5 });
    }
  }, []);
  return [translate, containerRef];
};

export function isNumeric(str) {
  return /^\d+$/.test(str) || /^\d+\.\d+$/.test(str);
}
