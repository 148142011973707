// import modular routes
import signInTemplateRoutes from "./signInTemplate";
import AffiliateTemplateRoutes from "./AffiliateTemplate";
import Home from "./Home";
import ManageMasterRoutes from "./ManageMaster";
import DistrictRoutes from "./District";
import StateRoutes from "./State";
import SubDistrictRoutes from "./SubDistrict";
import VillageRoutes from "./Village";
import BankRoutes from "./Bank";
import BranchRoutes from "./Branch";
import CategoryRoutes from "./Category";
import SubCategoryRoutes from "./SubCategory";
import OrganizationTypeRoutes from "./OrganizationType";
import SellerTemplateRoutes from "./SellerTemplate";
import SettingRoutes from "./Setting";
import LegalEntity from "./LegalEntity";
import ProductRoutes from "./Product";
import ProductVariantRoutes from "./ProductVariant";
import SellerProductVariantRoutes from "./SellerProductVariant";
import CustomerTemplateRoutes from "./CustomerTemplate";
import HubTemplateRoutes from "./Hub";
import BlockTemplateRoutes from "./Block";
import PinCodeTemplateRoutes from "./PinCode";
import FulfillmentcenterRoutes from "./Fulfillmentcenter";
import OrdersRoutes from "./Orders";
import Fulfill from "./Fulfill";
import Shipper from "./Shipper";
import Reports from "./Reports";
import UserRole from "./UserAccess/UserRole";
import PageCreation from "./UserAccess/PageCreation";
import PageMapping from "./UserAccess/PageMapping";
import Errors from "./Error";
import RoleActionMapping from "./UserAccess/RoleActionMapping";
import UserProfile from "./UserAccess/UserProfile";
import ComboProducts from "./ComboProducts";
import DeliveryPointRoutes from "./DeliveryPoint";
import FinancePage from "./FinancePage";
import SalesCommissionVoucher from "./FinancePage";
import SettingsPage from "./Setting";
import BlockVillageMappingPage from "./BlockVillageMapping";
import FutureFulfillment from "./FutureFulfillment";
import ProductActivation from "./ProductVariant";
import OffPlatformOrders from "./Orders";
import InstantOffSystemOrder from "./Orders";

export default [
  ...Home,
  ...Reports,
  ...Fulfill,
  ...ManageMasterRoutes,
  ...AffiliateTemplateRoutes,
  ...signInTemplateRoutes,
  ...DistrictRoutes,
  ...StateRoutes,
  ...SubDistrictRoutes,
  ...VillageRoutes,
  ...BankRoutes,
  ...BranchRoutes,
  ...CategoryRoutes,
  ...SubCategoryRoutes,
  ...OrganizationTypeRoutes,
  ...SellerTemplateRoutes,
  ...SettingRoutes,
  ...LegalEntity,
  ...ProductRoutes,
  ...ProductVariantRoutes,
  ...SellerProductVariantRoutes,
  ...CustomerTemplateRoutes,
  ...HubTemplateRoutes,
  ...BlockTemplateRoutes,
  ...PinCodeTemplateRoutes,
  ...FulfillmentcenterRoutes,
  ...OrdersRoutes,
  ...Shipper,
  ...Errors,
  ...UserRole,
  ...PageCreation,
  ...PageMapping,
  ...RoleActionMapping,
  ...UserProfile,
  ...ComboProducts,
  ...DeliveryPointRoutes,
  ...FinancePage,
  ...SalesCommissionVoucher,
  ...SettingsPage,
  ...BlockVillageMappingPage,
  ...FutureFulfillment,
  ...ProductActivation,
  ...OffPlatformOrders,
  ...InstantOffSystemOrder,
];
