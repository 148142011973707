// import lib
import { lazy } from 'react';

const routes = [
  {
    exact: true,
    path: '/admin_customer',
    component: lazy(() => import('../pages/CustomerTemplate')),
  },
  {
    exact: true,
    path: '/admin_customer/view/:customerId',
    component: lazy(() => import('../pages/CustomerTemplate/CustomerView')),
  },
  {
    exact: true,
    path: '/admin_customer/vieworder/:customerId',
    component: lazy(() => import('../pages/CustomerTemplate/CustomerOrder')),
  },
  {
    exact: true,
    path: '/admin_customer/vieworderitem/:orderId',
    component: lazy(() =>
      import('../pages/CustomerTemplate/CustomerOrderItem')
    ),
  },
];

export default routes;
