import { lazy } from 'react';

const routes = [
  {
    exact: true,
    path: '/category',
    component: lazy(() => import('../pages/Category')),
  },
  {
    exact: true,
    path: '/category/add',
    component: lazy(() => import('../pages/Category/create')),
  },
  {
    exact: true,
    path: '/category/edit/:id',
    component: lazy(() => import('../pages/Category/edit')),
  },
];

export default routes;
