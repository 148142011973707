import { lazy } from "react";

const routes = [
  {
    role: "ROLE_FULLFILLMENT_CENTER",
    exact: true,
    path: "/material-movement",
    component: lazy(() => import("../pages/material-movement")),
  },
  {
    role: "ROLE_FULLFILLMENT_CENTER",
    exact: true,
    path: "/lot_items/:lot_id",
    component: lazy(() => import("../pages/Fulfillment/LotItems")),
  },
  {
    role: "ROLE_FULLFILLMENT_CENTER",
    exact: true,
    path: "/reverse_lot_items/:lot_id",
    component: lazy(() => import("../pages/Fulfillment/ReverseLotItems")),
  },
  {
    role: "ROLE_FULLFILLMENT_CENTER",
    exact: true,
    path: "/fc_money_movement",
    component: lazy(() =>
      import("../pages/Fulfillment/FCCollectionLedger/page")
    ),
  },
];

export default routes;
