import { lazy } from "react";

const routes = [
  {
    exact: true,
    path: "/money_movement",
    component: lazy(() => import("../pages/Finance/pendingPayments")),
  },
  {
    exact: true,
    path: "/sales-commission-voucher",
    component: lazy(() => import("../pages/Finance/SalesCommissionVoucher")),
  },
];

export default routes;
